import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import VimeoPlayer from '../components/vimeoplayer/vimeoplayer'
import Callus from '../components/callus/callus'
import ButtonBlocks from "../components/buttonblocks/buttonblocks"
import ButtonBlock from "../components/buttonblock/buttonblock"


export default ({ data }) => {
  const post = data.videoblockpagesYaml
  return (
    <Layout>

      <SEO title={post.title} />

      <VimeoPlayer videoId={post.videoid} autoplay={post.autoplay} />

      <Callus />

      <ButtonBlocks>
        {post.blocks.map(block =>
          <ButtonBlock
            key={block.url}
            text={block.text}
            link={block.url}
            description={block.description}
          />
        )}
      </ButtonBlocks>

    </Layout>
  )
}

export const query = graphql`
  query($id: String!) {
    videoblockpagesYaml(id: { eq: $id }) {
      url
      title
      videoid
      autoplay
      blocks {
        text
        url
        description
      }
    }
  }
  `